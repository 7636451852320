@import 'theme';

$thumbnail-size: 130px;

img.header {
    width: 100%;
    max-width: 500px;
}

.metadata {
    font-size: 12px;
    color: $secondary-light;
}

.project-item-container {
    border-radius: 5px;
    transition: background-color ease 0.2s;
    padding: 10px;
    
    &:hover {
        background-color: $secondary-gray;
    }

    .heading {
        font-family: $title-font;
        font-size: 18px;  
        
        display: -webkit-box;        
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .title {
        position: relative;
        
        .heading {
            font-family: $title-font;
            font-size: 18px;  
            
            display: -webkit-box;        
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .subheading {
            font-size: 16px;
            color: $secondary-light;

            display: -webkit-box;        
            line-clamp: 4;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &.tags {
                font-size: 12px;
                position: absolute;
                bottom: 0;
                line-clamp: 1;
                -webkit-line-clamp: 1;
            }
        }
    }
}

.project-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow: hidden;
    //border-bottom: 1px solid $secondary-gray;

    .project-preview {
        display: flex;
    }
}

.date {
    color: $secondary-light;
    font-size: 12px;
}

.thumbnail {
    margin-right: 10px;
    object-fit: cover;

    &.mobile {
        margin-right: 0px;
        width: 100%;
    }
}

.table-of-contents-container {
    h3 {
        margin-bottom: 0px;
    }
    
    .contents {
        h3 {
            margin-top: 0px;
        }

        padding: 10px 10px 5px 10px;
        background-color: $primary-darker;
        margin: 5px 0px 5px 0px;
        border-radius: 5px;
    }

    .subheadings {
        margin-left: 15px;

        h4 {
            font-size: 14px;
            margin: 0px;
        }

        &:last-child {
            margin-bottom: 5px;
        }
    }
}

.img-paragraph {
    display: flex;
    flex-direction: row;
}

.left-img {
    max-width: 300px;
    max-height: 300px;
    width: 30%;
    object-fit: cover;
    border: 2px solid $secondary-dark;
    border-radius: 5px;
    margin-right: 10px;
}

div.center-img {
    text-align: center;

    img {
        max-width: 300px;
    }
}
