@import 'sass/theme';

body {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $primary-dark;
    color: $primary-white;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    
    font-size: 14px;
}

h1 {
    font-family: $title-font;
    font-weight: 100;
    margin-top: 5px;
    margin-bottom: 20px;
}

h2 {
    font-family: $title-font;
    font-weight: 100;
    margin-top: 5px;
    margin-bottom: 20px;
}

h3 {
    font-family: $title-font;
    font-weight: 100;
    margin-top: 5px;
    margin-bottom: 20px;
}

h4 {
    font-family: $title-font;
    font-weight: 100;
    margin-top: 5px;
    margin-bottom: 20px;
}

p {
    margin-top: 0px;
    margin-bottom: 10px;

    font-size: 16px;
}

div {
    &.separator {
        width: 100px;
        border-bottom: 3px solid $primary-yellow;
        margin-bottom: 10px;
        padding: 0;

        &.full-width {
            width: 100%;
        }

        &.maize {
            border-color: $primary-yellow;
        }

        &.blue {
            border-color: $secondary-dark;
        }
    }
}

a {
    color:  $primary-yellow;

    // &:visited {
    //     color:  #b46600;
    // }

    &.no-underline {
        text-decoration: none;
    }

    &.bare {
        text-decoration: none;
        color: inherit;
    }

    &.hover {
        color: $primary-white;
        
        &:hover {
            color: $primary-yellow;
        }
    }
}

.icon-link {
    cursor: pointer;
    color: $primary-white;

    &:hover {
        color: $primary-yellow;
    }
}

.flexbox-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    .col-50 {
        width: 50%;
    }

    .col-33 {
        width: 33%;
    }

    .col-67 {
        width: 67%;
    }

    .col-25 {
        width: 25%;
    }

    .col-75 {
        width: 75%;
    }

    .vert-center {
        align-items: center;
    }

    .horiz-center {
        justify-content: center;
    }
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.center-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

    &.full-page {
        height: 100%;
    }
}






