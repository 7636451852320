$primary-yellow: #FFCB05;
$primary-dark: rgb(40, 40, 40);
$primary-darker: rgb(30, 30, 30);
$primary-white: white;

$secondary-dark: #182029;
$secondary-gray: rgb(80, 80, 80);
$secondary-light: rgb(150, 150, 150);

$title-font: 'Montserrat';
$body-font: 'Open Sans';

$nav-width: 250px;
$nav-min-width: 200px;
$nav-max-width: 350px;