@import 'theme';

#site-content {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: calc(#{$nav-width} + 20px);//calc(min(calc(max(#{$nav-width}, #{$nav-min-width})), #{$nav-max-width}) + 20px);
    padding-right: 20px;
    height: 100vh;

    &.mobile {
        padding-top: 70px;
        padding-left: 20px;
    }

    //height: calc(100vh - 30px);
}

#site-container {
    // height: 100%;
}

#site-header {
    height: 60px;
    padding-left: 20px;
    display: none;
    align-items: center;
    background-color: $secondary-dark;
    position: fixed;
    width: 100%;
    z-index: 2;

    &.mobile {
        display: flex;
    }
}

.burger {
    transition: transform ease 0.6s;

    &.rotate {
        transform: rotate(90deg);
    } 
}