@import 'theme';

#site-nav {
    height: 100%;

    width: $nav-width;
    transform: translateX(-$nav-width);
    transition: transform ease 0.4s;

    background-color: $secondary-dark;
    position: fixed;
    height:100vh;
    left: 0;
    top: 0px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.show {
        transform: translateX(0);
        border-right: 2px solid black;
        box-shadow: 0px 0px 20px rgb(26, 26, 26);
    }
}

#nav-container {
    padding: 20px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    &.mobile {
        padding-top: 60px;
    }
}

#contact-container {
    // position: absolute;
    // bottom: 0;
    padding: 20px;
    z-index: 1;

    width: calc(#{$nav-width} - 40px);
    min-width: calc(#{$nav-min-width} - 40px);
    max-width: calc(#{$nav-max-width} - 40px);

    background-color: $secondary-dark;

    transform: translateX(calc(#{-$nav-width}));
    transition: transform ease 0.4s;

    .social-media-link {
        margin-right: 10px;
    }

    &.show {
        transform: translateX(0);
    }
}

.nav-header-container {
    margin-bottom: 10px;
    overflow: hidden;

    .nav-content {
        max-height: 0px;
        transition: max-height ease 0.4s;
        overflow-y: hidden;

        &.opened {
            max-height: 700px;
        }

        .nav-item {
            padding: 0px 10px 10px 10px;
            margin: 10px 10px 0px 20px;
            border-bottom: 1px solid gray;
            font-size: 14px;
        }
    }

    .nav-header {
        display: inline;
        align-items: center;
        font-family: $title-font;
        font-size: 18px;
        cursor: pointer; 

        a {
            text-decoration: none;
            color: $primary-white;
    
            &:visited {
                color: $primary-white;
            }

            &:hover {
                color: $primary-yellow;
            }
        }

        .nav-header-icon {
            margin-right: 10px;
            margin-left: 2px;
            transition: transform ease 0.4s;

            &.opened {
                transform: rotate(90deg);
            }
        }
    }
}
