@import 'theme';

#home {
    padding: 80px 50px 0px 50px;
}

#profile {
    border-radius: 200px;
    border: 2px solid $primary-yellow;
    height: 200px;
    width: 200px;
}

#profiles {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.profile-cl {
    height: 100px;
    padding: 50px;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
    .profile-cl {
        display: none;
    }
}

#home-flex {
    display: flex;
    justify-content: space-evenly;
    @media screen and (min-width: 0px) and (max-width: 1000px) {
        display: block;
    }
    
    .home-flex-item {
        flex-grow: 1;
        h3 {
            font-weight: bold;
        }
        //width: 33%;
    }
}